import React, { useState } from 'react'
import BaseButton from 'components/BaseButton'
import styled from 'styled-components'
import PackageSwr from 'services/swr/package'
import { message } from 'antd'
import ModalWrapper from './ModalWrapper'

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  p {
    text-align: center;
  }
`

const ModalUnstake = ({ visible, onCancel, modalData }) => {
  const { packageId, onSuccess } = modalData

  const [loading, setLoading] = useState(false)

  const handleUnstake = async () => {
    if (!packageId) return
    setLoading(true)

    await PackageSwr.unStake(
      { packageId },
      async () => {
        await onSuccess()
        setLoading(false)
        message.success('Unstake success!')
        onCancel()
      },
      async (e) => {
        setLoading(false)
        message.error(e?.message || 'Unstake failed!')
      }
    )
  }

  return (
    <ModalWrapper visible={visible} onCancel={onCancel} title="Confirm Unstake" width={500}>
      <ModalBody>
        <p>
          Do you want unstake your investment?
          <br />
          {!modalData?.end_date && "Still within the investment period. If you confirm unstake, 30% will be deducted."}
        </p>

        <BaseButton loading={loading} style={{ padding: 8, flex: 1 }} onClick={handleUnstake}>
          Confirm
        </BaseButton>
        <BaseButton background="#ffecf2" textColor="#ff1964" onClick={onCancel}>
          Close
        </BaseButton>
      </ModalBody>
    </ModalWrapper>
  )
}

export default ModalUnstake
