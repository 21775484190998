/* eslint-disable dot-notation */
/* eslint-disable no-new */
import 'assets/css/global.css'
import 'assets/css/animation.css'
import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import i18next from 'i18next'
import ModalConnectWallet from 'components/ModalConnectWallet'
import Layout from 'components/Layout'
import Popups from 'components/Popups'
import { EN, allLanguages } from 'constants/localization/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import { TranslationsContext } from 'hooks/TranslationsContext'
import Web3ReactManager from 'components/Web3ReactManager'
import { SWRConfig } from 'swr'

import Home from './Home'
import SignInPage from './Auth/SignIn'
import SignUpPage from './Auth/SignUp'
import ForgotPasswordPage from './Auth/ForgotPassword'
import RechargePage from './Recharge'
import Payment from './Recharge/Payment'
import Routes from './Routes'
import MyPage from './My'
import TransactionPage from './Transactions'
import SharePage from './Share'
import WithdrawPage from './Withdraw'
import WithdrawDetail from './Withdraw/WithdrawDetail'
import InvestmentPage from './Investment'
import InvestmentDetail from './Investment/InvestmentDetail'
import F1Page from './Referral/F1Page'
import Admin from './Admin/Edit'

const AppWrapper = styled.div`
  width: 100%;
  max-width: 576px;
  margin: 0 auto;
  overflow-x: hidden;

  background: url('/images/bg/main-bg.png') no-repeat;
  background-size: cover;
  background-position: center;
`
// Bg overview all page
const BodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const cacheLanguage = 'soupSwapLanguage'

export default function App() {
  const { isDark, toggleTheme } = useTheme()

  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])

  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter((language) => {
      return language.code === storedLangCode
    })[0]
  }

  useEffect(() => {
    if (!isDark) {
      toggleTheme()
    }
  }, [isDark, toggleTheme])

  useEffect(() => {
    i18next.changeLanguage(selectedLanguage?.code || 'en')
    if (selectedLanguage) localStorage.setItem(cacheLanguage, selectedLanguage?.code)
  }, [selectedLanguage])

  useEffect(() => {
    const storedLangCode = localStorage.getItem(cacheLanguage)
    if (storedLangCode) {
      const storedLang = getStoredLang(storedLangCode)
      setSelectedLanguage(storedLang)
    } else {
      setSelectedLanguage(EN)
    }
  }, [])

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <AppWrapper className={isDark ? 'theme-dark' : ''}>
          <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage, translatedLanguage, setTranslatedLanguage }}>
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              <SWRConfig>
                  <Layout>
                    <BodyWrapper>
                      <Popups />
                      <Web3ReactManager>
                        <Switch>
                          <Route exact strict path={Routes.home} component={Home} />
                          <Route exact strict path={Routes.my} component={MyPage} />
                          <Route exact strict path={Routes.admin} component={Admin} />

                          <Route exact strict path={Routes.signIn} component={SignInPage} />
                          <Route exact strict path={Routes.signUp} component={SignUpPage} />
                          <Route exact strict path={Routes.forgotPassword} component={ForgotPasswordPage} />

                          <Route exact strict path={Routes.recharge} component={RechargePage} />
                          <Route exact strict path={Routes.rechargePayment} component={Payment} />
                          <Route exact strict path={Routes.withdraw} component={WithdrawPage} />
                          <Route exact strict path={Routes.withdrawDetail} component={WithdrawDetail} />
                          <Route exact strict path={Routes.transactions} component={TransactionPage} />

                          <Route exact strict path={Routes.share} component={SharePage} />
                          <Route exact strict path={Routes.referral} component={F1Page} />

                          <Route exact strict path={Routes.investment} component={InvestmentPage} />
                          <Route exact strict path={Routes.investmentDetail} component={InvestmentDetail} />
                        </Switch>
                      </Web3ReactManager>
                      <ModalConnectWallet />
                    </BodyWrapper>
                  </Layout>
                </SWRConfig>
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
        </AppWrapper>
      </BrowserRouter>
    </Suspense>
  )
}
