import { Image } from 'antd'
import ModalEditToken from 'components/Modal/ModalEditToken'
import { Skeleton } from 'poodlefi-libs-uikit'
import React, { useState } from 'react'
import TokenSwr from 'services/swr/token'
import styled from 'styled-components'

const Wrapper = styled.div`
  .item-token {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    background: rgba(255, 221, 230, 0.6);
    padding: 12px;
    margin-bottom: 1px;
    cursor: pointer;

    span {
      font-size: 13px;
      line-height: 1;
      color: #9397a4;
    }
  }

  .content {
    flex: 1;
  }
`

const TokenList = () => {
  const { data: tokenData } = TokenSwr.useGetTokenList()

  const [activeModal, setActiveModal] = useState({
    open: false,
    modalData: undefined
  })

  return (
    <Wrapper>
      {!tokenData ? (
        <Skeleton width="100%" height={150} />
      ) : (
        tokenData?.map((item) => (
          <div className="item-token" onClick={() => setActiveModal({ open: true, modalData: item })}>
            <Image src={item?.logo} preview={false} width="40px" />
            <div className="content">
              <p>{item?.symbol}</p>
            </div>
          </div>
        ))
      )}

      <ModalEditToken visible={activeModal.open} onCancel={() => setActiveModal({ open: false, modalData: undefined })} modalData={activeModal.modalData}/>
    </Wrapper>
  )
}

export default TokenList
