import BaseButton from 'components/BaseButton'
import ModalBuyToken from 'components/Modal/ModalBuyToken'
import { Flex } from 'poodlefi-libs-uikit'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import AccountSwr from 'services/swr/account'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'

const Wrapper = styled.div`
  width: 100%;
  min-height: 200px;
  padding-left: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  background: url(/images/home/balance.png) no-repeat;
  background-size: contain;
  background-position: center;

  @media screen and (min-width: 430px) {
    min-height: 300px;
    padding-left: 40px;
    gap: 15px;
  }

  .level {
    color: #ff1964;
    font-weight: 700;
    font-size: 20px;

    @media screen and (min-width: 430px) {
      font-size: 24px;
    }
  }

  .label {
    color: #fe6ea2;
    font-weight: 500;

    @media screen and (min-width: 430px) {
      font-size: 20px;
    }
  }

  .content {
    font-size: 24px;
    font-weight: 700;

    @media screen and (min-width: 430px) {
      font-size: 42px;
    }

    span {
      font-size: 16px;
      position: relative;
      bottom: 2px;

      @media screen and (min-width: 430px) {
        bottom: 4px;
        font-size: 24px;
      }
    }
  }


`

const BalanceSection = () => {
  const history = useHistory()

  const { data: balances } = AccountSwr.useGetBalance()
  const { data: userInfo } = AccountSwr.useGetMyProfile()
  const [activeModal, setActiveModal] = useState(false)

  const toggleModal = () => setActiveModal(prev => !prev)

  return (
    <Wrapper>
      {userInfo && <p className="level">Your level: {userInfo?.user_account?.level}</p>}
      <p className="label">Total Balance</p>
      <p className="content">
        {!balances ? '--' : addCommasToNumber(+balances?.TRX)} <span>TRX</span>
      </p>
      <Flex alignItems="center" style={{ gap: 10 }}>
        <BaseButton width={120} onClick={() => history.push('/recharge')}>
          Deposit
        </BaseButton>
        <BaseButton width={120} background="#ffecf2" textColor="#ff1964" onClick={toggleModal}>
          Buy NEWP
        </BaseButton>
      </Flex>
      
      <ModalBuyToken visible={activeModal} onCancel={toggleModal}/>
    </Wrapper>
  )
}

export default BalanceSection
