import React, { useEffect, useState } from 'react'
import { Form, InputNumber, message } from 'antd'
import { FormWrapper } from 'pages/Auth/SignIn'
import styled from 'styled-components'
import BaseButton from 'components/BaseButton'
import { addCommasToNumber, toLocaleString } from 'utils'
import AccountSwr from 'services/swr/account'
import PackageSwr from 'services/swr/package'
import { formatUnits, parseUnits } from 'ethers/lib/utils'

import ModalWrapper from './ModalWrapper'

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  .form-wrapper,
  .ant-input-number {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .info-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`

const InvestInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  p {
    font-size: 14px;
  }

  .label {
    color: #adadad;
  }

  .value {
    color: #000;
    font-family: 500;
    text-align: right;
  }
`

const ModalInvestment = ({ visible, onCancel, modalData }) => {
  const packageLevel = modalData?.name?.split(' ')[1]

  const [form] = Form.useForm()
  const { data: balances, mutate: refreshBalances } = AccountSwr.useGetBalance()
  const { data: userInfo } = AccountSwr.useGetMyProfile()
  const { mutate: refreshHistory } = PackageSwr.useGetPackageHistory()

  const [loading, setLoading] = useState(false)

  const handleCancel = () => {
    if (loading) return
    form.resetFields()
    onCancel()
  }

  const handleConfirm = () => {
    if (!packageLevel || !userInfo || +packageLevel > +userInfo?.user_account?.level) return

    form.validateFields().then(async (values) => {
      const payload = {
        package_id: modalData?.id,
        amount_invest: toLocaleString(parseUnits(String(values.amount), 4)),
      }

      setLoading(true)

      await PackageSwr.invest(
        payload,
        async () => {
          await Promise.all([refreshHistory(), refreshBalances()])
          setLoading(false)
          handleCancel()
          message.success('Invest success!')
        },
        (e) => {
          setLoading(false)
          message.error(e?.message || 'Invest failed!')
        }
      )
    })
  }

  useEffect(() => {
    if (typeof modalData?.id === 'undefined') return
    form.setFieldsValue({
      amount: +formatUnits(modalData?.min_amount, 4)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData?.id, modalData?.min_amount])

  return (
    <ModalWrapper visible={visible} onCancel={handleCancel} title="Investment">
      <ModalBody>
        <FormWrapper className="form-wrapper">
          <Form form={form} layout="vertical">
            <Form.Item
              label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                  message: 'Enter amount',
                },
                balances
                  ? () => ({
                    validator(_, value) {
                      if (!value || balances?.NEWP >= value) return Promise.resolve()
                      return Promise.reject(new Error('Balance insufficient'))
                    },
                  })
                  : {},
              ]}
            >
              <InputNumber
                width="100%"
                // value={!modalData ? 0 : +formatUnits(modalData?.min_amount, 4)}
                min={modalData?.min_amount / 1e4}
                max={modalData?.max_amount / 1e4}
                placeholder="Investment amount"
                disabled
              />
            </Form.Item>
          </Form>
        </FormWrapper>

        <div className="info-list">
          <InvestInfo>
            <p className="label">Package name</p>
            <p className="value">{modalData?.name}</p>
          </InvestInfo>

          <InvestInfo>
            <p className="label">Currency</p>
            <p className="value">{modalData?.currency}</p>
          </InvestInfo>

          <InvestInfo>
            <p className="label">Package amount</p>
            <p className="value">{addCommasToNumber(modalData?.min_amount / 1e4, 0)}</p>
          </InvestInfo>

          {/* <InvestInfo>
            <p className="label">Max amount</p>
            <p className="value">{addCommasToNumber(modalData?.max_amount / 1e4, 0)}</p>
          </InvestInfo> */}

          <InvestInfo>
            <p className="label">Daily Earnings</p>
            <p className="value">{(+modalData?.daily_interest_rate / 100).toFixed(3)}%</p>
          </InvestInfo>
        </div>

        <BaseButton
          loading={!balances || loading || !userInfo}
          disabled={!modalData?.id || !packageLevel || +packageLevel > +userInfo?.user_account?.level}
          onClick={handleConfirm}
        >
          {+packageLevel > +userInfo?.user_account?.level ? `Must be level ${packageLevel}` : 'Confirm'}
        </BaseButton>
      </ModalBody>
    </ModalWrapper>
  )
}

export default ModalInvestment
