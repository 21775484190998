import React, { useEffect, useState } from 'react'
import { Table, Tooltip } from 'antd'
import { ADDRESS_WITHDRAW } from 'constants/index'
import moment from 'moment'
import { Skeleton } from 'poodlefi-libs-uikit'
import TransactionSwr from 'services/swr/transaction'
import { shortenString } from 'utils'
import { formatAmount } from 'utils/formatInfoNumbers'


const AllTransaction = ({ type }: { type: 'DEPOSIT' | 'CLAIM' | 'ALL' | 'WITHDRAW' }) => {
  const { data } = TransactionSwr.useGetMyTransaction()
  const [dataShow, setDataShow] = useState<any>()

  const columns = [
    {
      title: type !== 'WITHDRAW' ? 'From' : 'To',
      dataIndex: type !== 'WITHDRAW' ? 'from' : 'to',
      key: 'from',
      render: (address, record) =>
        address === ADDRESS_WITHDRAW ? (
          'WITHDRAW'
        ) : (
          <>
            {`${address}`?.length === 34 ? (
              <a href={`https://bscscan.com/address/${address}`} target="_Blank" rel="noreferrer">
                {shortenString(address)}
              </a>
            ) : (
              <p>
                {address} <br /> {record?.blockNumber === 'CLAIM_INTERSET' ? record?.hash : ''}
              </p>
            )}
          </>
        ),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (value, record) => {
        const balance = value / (10 ** +record?.token_decimals)
        return (
          <p>
            {record?.from === ADDRESS_WITHDRAW && '-'}
            {balance < 0.01 && balance > -0.01 ? `${balance}`.slice(0, 6) : formatAmount(balance)} {record?.token_symbol}
          </p>
        )
      },
    },
    {
      title: 'Time',
      dataIndex: 'timeStamp',
      key: 'timeStamp',
      render: (timeStamp) => (
        <p>
          <Tooltip placement="top" title={moment(+timeStamp).format('YYYY-MM-DD HH:mm:ss')}>
            {moment(+timeStamp).format('DD/MM/YYYY')}
          </Tooltip>
        </p>
      ),
    },
  ]

  useEffect(() => {
    if (data) {
      if (type === 'DEPOSIT') {
        setDataShow(data?.filter((item) => item?.blockHash?.length === 64))
        return
      }

      if (type === 'CLAIM') {
        setDataShow(data?.filter((item) => item?.blockNumber === 'CLAIM_INTERSET'))
        return
      }

      if (type === 'WITHDRAW') {
        setDataShow(data?.filter((item) => item?.isWithdraw))
        return
      }

      setDataShow(data)
    }
  }, [data, type])

  return !data ? (
    <Skeleton width="100%" height={200} />
  ) : (
    <Table
      dataSource={dataShow}
      columns={columns}
      scroll={undefined}
      loading={!data}
      pagination={{
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
      rowKey={(item) => item?.id}
    />
  )
}

export default AllTransaction
