import { Image } from 'antd'
import BaseButton from 'components/BaseButton'
import BackButton from 'components/BaseButton/BackButton'
import React from 'react'
import { useHistory } from 'react-router-dom'
import AccountSwr from 'services/swr/account'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'

const Wrapper = styled.div`
  .wrap-item-token {
    min-height: 50vh;
    margin: 24px -20px;

    .item-token {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      background: rgba(255, 221, 230, 0.6);
      padding: 12px;
      margin-bottom: 1px;
      cursor: pointer;

      span {
        font-size: 13px;
        line-height: 1;
        color: #9397a4;
      }
    }

    .content {
      flex: 1;
    }

    .amount {
      font-size: 20px;
      font-weight: 700;
    }
  }
`

const RechargePage = () => {
  const history = useHistory()
  const { data: balances } = AccountSwr.useGetBalance()

  return (
    <Wrapper>
      <BackButton title="Accepted tokens" />

      <div className="wrap-item-token">
        <div className="item-token" onClick={() => history.push('/recharge/payment')}>
          <Image src="/images/coins/TRX.png" preview={false} width="50px" />

          <div className='content'>
            <p>TRX</p>
            <span>Exchange Rate 1:1 TRX</span>
          </div>

          <div className="amount">
            {!balances ? '--' : addCommasToNumber(+balances?.TRX)}
          </div>
        </div>

        <div className="item-token" onClick={() => history.push('/recharge/payment')}>
          <Image src="/images/coins/tether_trc20.png" preview={false} width="50px" />

          <div className='content'>
            <p>TRC20-USDT</p>
          </div>

          <div className="amount">
            {!balances ? '--' : addCommasToNumber(+balances?.USDT)}
          </div>
        </div>
        
        <div className="item-token" onClick={() => history.push('/recharge/payment')}>
          <Image src="/images/tokens/NEWP-V1.png" preview={false} width="50px" />

          <div className='content'>
            <p>NEWP</p>
          </div>

          <div className="amount">
            {!balances ? '--' : addCommasToNumber(+balances?.NEWP)}
          </div>
        </div>
      </div>

      <BaseButton onClick={() => history.push('/recharge/payment')}>Confirm</BaseButton>
    </Wrapper>
  )
}

export default RechargePage
