import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Form, InputNumber, message, Select } from 'antd'
import AccountSwr from 'services/swr/account'
import TokenSwr from 'services/swr/token'
import TransactionSwr from 'services/swr/transaction'
import { Text } from 'poodlefi-libs-uikit'
import BaseButton from 'components/BaseButton'
import { FormWrapper } from 'pages/Auth/SignIn'
import ModalWrapper from './ModalWrapper'

const { Option } = Select

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  .form-wrapper,
  .ant-input-number {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .select-token-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;

    .ant-form-item-control {
      flex-grow: 0;
      width: fit-content;
      min-width: 100px;
    }

    .select-token {
      .ant-select {
        .ant-select-selection-placeholder {
          color: #000;
        }
      }
    }
  }
`

const ModalBuyToken = ({ visible, onCancel }) => {
  const { data: balances, mutate: refreshBalances } = AccountSwr.useGetBalance()
  const { data: tokenData } = TokenSwr.useGetTokenList()
  const [form] = Form.useForm()

  const [currency, setCurrency] = useState({
    from: "USDT",
    to: "NEWP",
  })
  const [amountUSDT, setAmountUSDT] = useState<any>(0)
  const [loading, setLoading] = useState(false)

  const tokenPrice = useMemo(() => {
    if (!tokenData) return undefined
    
    const foundToken = tokenData?.find(x => x?.symbol === currency.to)
    if (foundToken) return foundToken.price

    return undefined
  }, [tokenData, currency.to])

  const handleSelectToken = (token, type) => {
    setAmountUSDT(0)
    form.setFieldsValue({
      amount: undefined,
    })
    setCurrency((prev) => ({
      ...prev,
      [type]: token,
    }))
  }

  const handleClose = () => {
    if (loading) return
    form.resetFields()
    setCurrency({
      from: "USDT",
      to: "NEWP",
    })
    form.setFieldsValue({
      amount: undefined,
      currencyFrom: "USDT",
      currencyTo: "NEWP"
    })
    setAmountUSDT(0)
    onCancel()
  }

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      setLoading(true)

      await TransactionSwr.swap(
        values,
        async () => {
          await Promise.all([refreshBalances()])
          setLoading(false)
          message.success('Swap success!')
          handleClose()
        },
        (e) => {
          setLoading(false)
          message.error(e?.message || 'Swap failed!')
        }
      )
    })
  }

  const isComingSoon = (currency.from && currency.from !== 'USDT') || (currency.to && currency.to !== 'NEWP')

  useEffect(() => {
    form.setFieldsValue({
      currencyFrom: "USDT",
      currencyTo: "NEWP"
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalWrapper visible={visible} onCancel={handleClose} title="Swap Token" width={500}>
      <ModalBody>
        <FormWrapper className="form-wrapper">
          <Form form={form} layout="vertical">
            <Form.Item
              className="select-token-wrapper"
              label="From"
              name="currencyFrom"
              rules={[
                {
                  required: true,
                  message: 'Select token',
                },
              ]}
            >
              <Select placeholder="Token" onChange={(val) => handleSelectToken(val, 'from')} defaultValue="USDT">
                <Option value="TRX" disabled={currency.to === 'TRX'}>
                  TRX
                </Option>
                <Option value="USDT" disabled={currency.to === 'USDT'}>
                  USDT
                </Option>
                <Option value="NEWP" disabled={currency.to === 'NEWP'}>
                  NEWP
                </Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={undefined}
              name="amount"
              rules={[
                {
                  required: true,
                  message: 'Enter amount',
                },
                balances
                  ? ({ getFieldValue }) => {
                      const currencyFrom = getFieldValue('currencyFrom')

                      return {
                        validator(_, value) {
                          if (value === 0) return Promise.reject(new Error('Enter amount'))
                          if (!value || balances?.[currencyFrom] >= value) return Promise.resolve()
                          return Promise.reject(new Error('Balance insufficient'))
                        },
                      }
                    }
                  : {},
              ]}
            >
              <InputNumber
                width="100%"
                placeholder="Enter amount"
                disabled={!balances || !currency.to || !currency.from || !tokenData}
                onChange={(val) => currency.from === 'USDT' && setAmountUSDT(val)}
              />
            </Form.Item>

            {currency.from && (
              <Text color="#191919" fontSize="12px" mb="12px">
                Balance: {balances?.[currency.from]}
              </Text>
            )}

            <Form.Item
              className="select-token-wrapper"
              label="To"
              name="currencyTo"
              rules={[
                {
                  required: true,
                  message: 'Select token',
                },
              ]}
            >
              <Select placeholder="Token" onChange={(val) => handleSelectToken(val, 'to')} defaultValue="NEWP">
                <Option value="TRX" disabled={currency.from === 'TRX'}>
                  TRX
                </Option>
                <Option value="USDT" disabled={currency.from === 'USDT'}>
                  USDT
                </Option>
                <Option value="NEWP" disabled={currency.from === 'NEWP'}>
                  NEWP
                </Option>
              </Select>
            </Form.Item>

            <Form.Item label={undefined}>
              <InputNumber
                width="100%"
                value={
                  currency.from && currency.from === 'USDT' && currency.to && currency.to === 'NEWP' ? amountUSDT / tokenPrice : 0
                }
                readOnly
              />
            </Form.Item>
          </Form>

          <BaseButton onClick={handleSubmit} disabled={isComingSoon} loading={loading}>
            {isComingSoon ? 'Coming soon' : 'Confirm'}
          </BaseButton>
        </FormWrapper>
      </ModalBody>
    </ModalWrapper>
  )
}

export default ModalBuyToken
