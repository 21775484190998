import { Form, Input, message } from 'antd'
import BaseButton from 'components/BaseButton'
import { Text } from 'poodlefi-libs-uikit'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { authService } from 'services'
import styled from 'styled-components'
import Routes from 'pages/Routes'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`

export const FormWrapper = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media screen and (min-width: 430px) {
    width: 80%;
  }

  .ant-form {
    width: 100%;

    label {
      color: #191919;
      font-weight: bold;
      font-size: 16px;
    }

    input {
      border-radius: 16px;
      color: #ff82af;

      ::placeholder {
        color: #837d7f;
      }
    }

    .ant-input-affix-wrapper {
      padding: 0 11px;
      border-radius: 16px;
      background-color: #fff1f5 !important;
      border-color: transparent;

      input {
        border: none !important;
        box-shadow: none !important;
      }
    }

    .ant-form-item-explain {
      div {
        font-size: 10px;
      }
    }

    .ant-input-number {
      border-radius: 16px;
    }

    .ant-input-number-disabled .ant-input-number-input {
      background-color: #f5f5f5;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;

  @media screen and (min-width: 430px) {
    width: 80%;
    padding-bottom: 60px;
  }
`

const SignInPage = () => {
  const history = useHistory()
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      setLoading(true)

      await authService.signIn(
        values,
        () => {
          setLoading(false)
          message.success('Login success!')
          history.push(Routes.home)
        },
        (e) => {
          setLoading(false)
          message.error(e?.message || 'Login failed!')
        }
      )
    })
  }

  return (
    <Wrapper>
      <FormWrapper>
        <Form form={form} layout="vertical">
          <Form.Item
            label="Account"
            name="username"
            rules={[
              {
                required: true,
                message: 'Username is require',
              },
            ]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: 'Password is require' },
              { min: 8, message: 'The password must be at least 8 characters.' },
            ]}
          >
            <Input.Password placeholder="Password" autoComplete="false" />
          </Form.Item>
        </Form>
      </FormWrapper>

      <ButtonWrapper>
        <Text
          textAlign="right"
          color="#fe6ea2"
          fontSize="16px"
          style={{ cursor: 'pointer', width: '100%' }}
          onClick={() => history.push('/auth/forgot-password')}
        >
          Forgot Password?
        </Text>

        <BaseButton onClick={handleSubmit} loading={loading}>Login</BaseButton>
        <BaseButton background="#ffecf2" textColor="#ff1964" onClick={() => history.push('/auth/sign-up')}>
          Sign Up
        </BaseButton>

        <Text
          textAlign="center"
          color="#fe6ea2"
          fontSize="16px"
          style={{ cursor: 'pointer', width: '100%' }}
          onClick={() => history.push('/')}
        >
          Home
        </Text>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default SignInPage
