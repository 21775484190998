import React, { useMemo, useState } from 'react'
import BackButton from 'components/BaseButton/BackButton'
import { useHistory } from 'react-router-dom'
import AccountSwr from 'services/swr/account'
import styled from 'styled-components'
import { Skeleton } from 'poodlefi-libs-uikit'
import { addCommasToNumber } from 'utils'
import { Checkbox, Form, Input, InputNumber, message } from 'antd'
import BaseButton from 'components/BaseButton'
import { FormWrapper } from 'pages/Auth/SignIn'
import PackageSwr from 'services/swr/package'
import ModalLoadingWithdraw from 'components/Modal/ModalLoadingWithdraw'
import TokenSwr from 'services/swr/token'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .form-wrapper,
  .ant-input-number {
    width: 100%;
  }

  .ant-input-number {
    border-radius: 16px;
  }

  .ant-form-item:last-child {
    margin-bottom: 0;
  }

  .ant-checkbox-wrapper {
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  .fee {
    width: 100%;

    span {
      font-weight: bold;
    }
  }
`

const Balances = styled.div`
  background-image: url(/images/bg/withdraw-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  padding: 24px 16px;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    color: #fff;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
  }

  .value {
    font-size: 24px;
    font-weight: 700;

    span {
      font-size: 14px;
      position: relative;
      bottom: 2px;
    }
  }
`

const WithdrawDetail = ({ location }) => {
  const [form] = Form.useForm()
  const history = useHistory()
  const token = location.pathname.split('/withdraw/')[1]

  const { data: balances, mutate: refreshBalances } = AccountSwr.useGetBalance()
  const { mutate: refreshHistory } = PackageSwr.useGetPackageHistory()
  const { data: tokenData } = TokenSwr.useGetTokenList()

  const [loading, setLoading] = useState(false)
  const tokenInfo = useMemo(() => {
    if (!tokenData || !token) return undefined

    return tokenData?.find((x) => x?.symbol === token)
  }, [tokenData, token])

  if (!balances) return <Skeleton width="100%" height={400} />
  if (!token || typeof balances?.[token] === 'undefined') {
    history.goBack()
    return <></>
  }

  const handleConfirm = () => {
    form.validateFields().then(async (values) => {
      if (!token) {
        message.error('Token not found')
        return
      }

      if (+balances?.TRX < tokenInfo?.fee_withdraw) {
        message.error('Balance TRX insufficient')
        return
      }

      const { amount, address } = values

      const payload = {
        amountWithdraw: amount,
        currency: token.toUpperCase(),
        toAddress: address,
      }

      setLoading(true)

      await AccountSwr.withdraw(
        payload,
        async () => {
          await Promise.all([refreshHistory(), refreshBalances()])
          setLoading(false)
          message.success('Withdraw success!')
          form.resetFields()
        },
        (e) => {
          setLoading(false)
          message.error(e?.message || 'Withdraw failed!')
        }
      )
    })
  }

  return (
    <Wrapper>
      <BackButton title={token} />

      <Balances>
        <p className="title">Available Balance</p>
        <p className="value">
          {!balances ? '--' : addCommasToNumber(+balances?.[token])} <span>{token}</span>
        </p>
      </Balances>

      <FormWrapper className="form-wrapper">
        <Form form={form} layout="vertical">
          <Form.Item
            label="Address"
            name="address"
            rules={[
              {
                required: true,
                message: 'Enter address',
              },
            ]}
          >
            <Input width="100%" placeholder="Enter address" />
          </Form.Item>

          <Form.Item
            label="Withdraw amount"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Enter amount',
              },
              balances
                ? () => ({
                    validator(_, value) {
                      if (!value || balances?.TRX >= value) return Promise.resolve()
                      return Promise.reject(new Error('Balance insufficient'))
                    },
                  })
                : {},
            ]}
          >
            <InputNumber width="100%" placeholder="Enter amount" max={+balances?.[token] || 0} />
          </Form.Item>

          <Form.Item
            name="confirm"
            valuePropName="checked"
            label={null}
            rules={[
              () => ({
                validator(_, value) {
                  if (value) return Promise.resolve()
                  return Promise.reject(new Error('Please confirm before withdraw'))
                },
              }),
            ]}
          >
            <Checkbox>
              Please double-check the address before proceeding. Any losses due to incorrect address input will not be supported
            </Checkbox>
          </Form.Item>
        </Form>

        <p className="fee">
          Withdraw fee: <span>{tokenInfo?.fee_withdraw || '--'} {tokenInfo?.token_fee || '--'}</span>
        </p>
      </FormWrapper>

      <BaseButton
        loading={loading}
        onClick={handleConfirm}
        disabled={!tokenInfo || !balances || +balances?.TRX < tokenInfo?.fee_withdraw}
      >
        {balances?.TRX < tokenInfo?.fee_withdraw ? 'Balance TRX insufficient' : 'Withdraw'}
      </BaseButton>
      <ModalLoadingWithdraw visible={loading} onCancel={() => true} />
    </Wrapper>
  )
}

export default WithdrawDetail
