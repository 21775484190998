import { message } from 'antd'
import BaseButton from 'components/BaseButton'
import ModalUnstake from 'components/Modal/ModalUnstake'
import { formatUnits } from 'ethers/lib/utils'
import moment from 'moment-timezone'
import { Box, Flex } from 'poodlefi-libs-uikit'
import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import PackageSwr from 'services/swr/package'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'

const Wrapper = styled.div`
  background-image: url(/images/bg/withdraw-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  padding: 16px;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

const RowInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  p {
    font-size: 12px;
    color: #fff;

    @media screen and (min-width: 430px) {
      font-size: 20px;
    }
  }

  .label {
    color: #ddd;
  }

  .value {
    font-family: 500;
    text-align: right;

    &.link {
      text-decoration: underline;
      color: #8afa97;
      cursor: pointer;
    }
  }
`

const PackageItem = ({ history, onSuccess, onViewHistory }) => {
  const { amount, start_date, id: packageId, interest_claim_history: claimHistory } = history
  const { name, currency, daily_interest_rate } = history.package

  let decimal = 6
  if (currency === "NEWP") decimal = 4

  const [value, setValue] = useState({
    old: 0,
    current: 0,
  })
  const [loading, setLoading] = useState(false)
  const [modalUnstake, setModalUnstake] = useState(false)

  const toggleModal = () => setModalUnstake(prev => !prev)

  const handleClaim = async () => {
    if (!packageId) return
    setLoading(true)

    await PackageSwr.claimReward(
      { packageId },
      async () => {
        await onSuccess()
        setLoading(false)
        message.success('Claim success!')
      },
      (e) => {
        setLoading(false)
        message.error(e?.message || 'Claim failed!')
      }
    )
  }

  useEffect(() => {
    let date = start_date

    if (claimHistory) {
      date = claimHistory?.[claimHistory.length - 1]?.claimed_at
    }

    const interval = setInterval(() => {
      setValue((prev) => ({
        old: prev.current,
        current: +formatUnits(amount, decimal) * (moment(Date.now()).unix() - date) * ((daily_interest_rate / 10000) / 86400),
      }))
    }, 3000)

    return () => clearInterval(interval)
  }, [amount, start_date, claimHistory, daily_interest_rate, decimal])

  useEffect(() => {
    let date = start_date

    if (claimHistory) {
      date = claimHistory?.[claimHistory.length - 1]?.claimed_at
    }

    setValue((prev) => ({
      old: prev.current,
      current: +formatUnits(amount, decimal) * (moment(Date.now()).unix() - date) * ((daily_interest_rate / 10000) / 86400),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      <RowInfo>
        <p className="label">Name</p>
        <p className="value">{name}</p>
      </RowInfo>

      <RowInfo>
        <p className="label">Amount</p>
        <p className="value">
          {addCommasToNumber(+formatUnits(amount, decimal))} {currency}
        </p>
      </RowInfo>

      <RowInfo>
        <p className="label">Start date</p>
        <p className="value">{moment.utc(start_date * 1000).format('DD/MM/YYYY')}</p>
      </RowInfo>

      <RowInfo>
        <p className="label">Daily interest rate</p>
        <p className="value" style={{ color: '#8afa97' }}>
          {+daily_interest_rate / 100}%
        </p>
      </RowInfo>

      <RowInfo>
        <p className="label">Cumulative total reward</p>
        <p className="value">
          <CountUp start={value?.old} end={value?.current} duration={1} separator="," decimals={4} decimal="." />
        </p>
      </RowInfo>

      <RowInfo>
        <p className="label">Claim history</p>
        <Box
          as="p"
          className="value link"
          onClick={() => onViewHistory(claimHistory ? claimHistory?.sort((a, b) => b?.claimed_at - a?.claimed_at) : [])}
        >
          View
        </Box>
      </RowInfo>

      <Flex alignItems="center" width="100%" style={{ gap: 10 }}>
        <BaseButton disabled={value.current < (+formatUnits(amount, decimal)) * 0.1} loading={loading} style={{ padding: 8, flex: 1 }} onClick={handleClaim}>
          Claim
        </BaseButton>
        <BaseButton background="#ffecf2" textColor="#ff1964" loading={loading} style={{ padding: 8, flex: 1 }} onClick={toggleModal}>
          Unstake
        </BaseButton>
      </Flex>

      <ModalUnstake
        visible={modalUnstake}
        onCancel={toggleModal}
        modalData={{ packageId, onSuccess }}
      />
    </Wrapper>
  )
}

export default PackageItem
