import { Col, Image, Row } from 'antd'
import BaseButton from 'components/BaseButton'
import ModalInvestment from 'components/Modal/ModalInvestment'
import { Skeleton } from 'poodlefi-libs-uikit'
import React, { useState } from 'react'
import AccountSwr from 'services/swr/account'
import PackageSwr from 'services/swr/package'
import styled from 'styled-components'
import { formatAmountOrigin } from 'utils/formatInfoNumbers'

const Wrapper = styled.div`
  margin-top: 30px;
  padding: 30px 10px;
  background: #fff;
  box-shadow: 1px 1px 10px #00000030;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  h2 {
    font-size: 24px;
    color: #000;
    text-align: center;
    font-weight: bold;

    @media screen and (min-width: 430px) {
      font-size: 32px;
    }
  }

  .ant-row {
    width: 100%;
  }

  .more {
	text-align: center;
    color: #ababab;
	font-size: 16px;
	cursor: pointer;

	@media screen and (min-width: 430px) {
      font-size: 20px;
    }
  }
`

const InvestmentCard = styled.div`
  cursor: pointer;
  background: linear-gradient(#e9b5cc, #eb74a8);
  padding: 12px 8px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (min-width: 430px) {
    padding: 16px;
  }

  .ant-image-img {
    border-radius: 8px;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    &-header {
      color: #fff;
      font-size: 14px;
      text-align: center;

      @media screen and (min-width: 430px) {
        font-size: 18px;
      }
    }

    &-body {
      p {
        font-size: 12px;
        text-align: center;

        @media screen and (min-width: 430px) {
          font-size: 16px;
        }

        &.value {
          font-weight: bold;
        }
      }
    }

    button {
        font-size: 10px;
        padding: 4px 8px;

        @media screen and (min-width: 430px) {
          font-size: 14px;
        }
    }
  }

  button:disabled {
    filter: grayscale(0.7);
    background: #ccc;
  }
`

const InvestmentSection = () => {
  const { data: packageList } = PackageSwr.useGetMyPackageList()
  const { data: userInfo } = AccountSwr.useGetMyProfile()

  const [modalInvest, setModalInvest] = useState({
    toggle: false,
    modalData: undefined
  })

  return (
    <Wrapper>
      <h2>Smart Investment</h2>

      <Row gutter={[10, 20]}>
        {!userInfo || !packageList ? <Skeleton width="100%" height={200} /> : packageList?.map((item) => {
          const packageLevel = item?.name?.split(' ')[1]
          const isWrongLevel = +packageLevel > +userInfo?.user_account?.level

          return (
            <Col span={12} key={item?.id}>
              <InvestmentCard onClick={() => setModalInvest({ toggle: true, modalData: item })}>
                <Image src="/images/home/silder-2.png" preview={false} />
                <div className="card-content">
                  <p className="card-content-header">{item?.name}</p>
                  <div className="card-content-body">
                    <p>Amount</p>
                    {/* <p className='value'>{formatAmountOrigin(item?.min_amount / 1e4)}-{formatAmountOrigin(item?.max_amount / 1e4)} {item?.currency}</p> */}
                    <p className='value'>{formatAmountOrigin(item?.min_amount / 1e4)} {item?.currency}</p>
                    <p>Daily Earnings</p>
                    <p className='value'>{(item?.daily_interest_rate / 100).toFixed(3)}%</p>
                  </div>

                  <BaseButton background="#fff" textColor="#fe6ea2" disabled={isWrongLevel}>
                    {isWrongLevel ? `Must be level ${packageLevel}` : "Invest Now"}
                  </BaseButton>
                </div>

              </InvestmentCard>
            </Col>
          )
        })}
      </Row>

      <ModalInvestment visible={modalInvest.toggle} onCancel={() => setModalInvest({ toggle: false, modalData: undefined })} modalData={modalInvest.modalData} />
    </Wrapper>
  )
}

export default InvestmentSection
