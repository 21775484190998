import fetchWrapper from 'helpers/fetch-wrapper'
import useSWR, { SWRConfiguration } from 'swr'
import SWR_KEY from './constant'

const useGetTokenList = (configs?: SWRConfiguration) => {
  const fetchData = async () => fetchWrapper.get('/tokens/get-list').then((res) => res?.data?.map(token  => {
    const tokenFeeData = res?.data?.find(x => x?.symbol === token?.token_fee)

    return {
      ...token,
      fee_withdraw: +token?.fee_withdraw / (10 ** +tokenFeeData?.decimals),
      fee_swap: +token?.fee_swap / (10 ** +tokenFeeData?.decimals),
      price: +token?.price / (10 ** +token?.decimals),
      logo: `/images/tokens/${token?.symbol}-V1.png`,
      tokenFeeDecimals: tokenFeeData?.decimals
    }
  }))

  return useSWR(SWR_KEY.GET_TOKEN_LIST, fetchData, {
    revalidateOnFocus: false,
	...configs
  })
}

const edit = async (body: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.post(`/token/edit/${body?.id}`, body).then(async (data: any) => {
    if ((data?.status === 200 || data?.status === 201)) {
      if (cbs) cbs(data?.data)
      return data
    }
    if (cbe) cbe(data?.data)
    return data
  })
}

const TokenSwr = {
	useGetTokenList,
  edit
} 

export default TokenSwr
