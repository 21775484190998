import { Form, Input, InputNumber, message } from 'antd'
import BaseButton from 'components/BaseButton'
import { FormWrapper } from 'pages/Auth/SignIn'
import { Flex } from 'poodlefi-libs-uikit'
import React, { useEffect, useState } from 'react'
import TokenSwr from 'services/swr/token'
import styled from 'styled-components'
import { toLocaleString } from 'utils'
import { parseUnits } from 'ethers/lib/utils'
import ModalWrapper from './ModalWrapper'

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  .form-wrapper,
  .ant-input-number {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 12px;
    justify-content: space-between;
    gap: 10px;
  }

  .select-token-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;

    .ant-form-item-control {
      flex-grow: 0;
      width: fit-content;
      min-width: 100px;
    }

    .select-token {
      .ant-select {
        .ant-select-selection-placeholder {
          color: #000;
        }
      }
    }
  }

  .ant-form-item-control {
    flex: 1 !important;
    max-width: 60% !important;

    @media screen and (min-width: 430px) {
      max-width: 65% !important;
    }
  }

  .ant-form-item-label {
    max-width: fit-content !important;
  }

  label {
    font-size: 12px !important;

    @media screen and (min-width: 430px) {
      font-size: 14px !important;
    }
  }
`

const ModalEditToken = ({ visible, onCancel, modalData }: { visible: boolean; onCancel: () => void; modalData?: any }) => {
  const [form] = Form.useForm()

  const { mutate: refreshTokenData } = TokenSwr.useGetTokenList()
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const handleClose = () => {
    if (loading) return
    form.resetFields()
    setIsEdit(false)
    onCancel()
  }

  const handleSubmit = () => {
    if (!modalData?.id) return null
    if (!isEdit) return setIsEdit(true)

    form.validateFields().then(async (values) => {
      const { decimals, price } = values
      const { fee_withdraw, can_swap, can_withdraw, id, tokenFeeDecimals } = modalData

      setLoading(true)

      await TokenSwr.edit(
        {
          ...values,
          price: toLocaleString(parseUnits(String(price), decimals)),
          id,
          fee_withdraw: toLocaleString(parseUnits(String(fee_withdraw), tokenFeeDecimals)),
          can_swap,
          can_withdraw,
        },
        async () => {
          await Promise.all([refreshTokenData()])
          setLoading(false)
          message.success('Token updated successfully!')
          handleClose()
        },
        (e) => {
          setLoading(false)
          message.error(e?.message || 'Token updated failed!')
        }
      )
    })

    return null
  }

  useEffect(() => {
    if (!modalData?.id) return

    const { name, symbol, address, decimals, network, fee_swap, fee_withdraw, token_fee, can_swap, price, can_withdraw } =
      modalData

    form.setFieldsValue({
      name,
      symbol,
      address,
      decimals,
      network,
      fee_swap,
      fee_withdraw,
      token_fee,
      price,
      can_swap,
      can_withdraw,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData?.id])

  return (
    <ModalWrapper visible={visible} onCancel={handleClose} title={`${modalData?.symbol} Token`} width={540}>
      <ModalBody>
        <FormWrapper className="form-wrapper">
          <Form form={form} layout="horizontal">
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Enter name',
                },
              ]}
            >
              <Input placeholder="Token name" disabled />
            </Form.Item>

            <Form.Item
              label="Symbol"
              name="symbol"
              rules={[
                {
                  required: true,
                  message: 'Enter symbol',
                },
              ]}
            >
              <Input placeholder="Token symbol" disabled />
            </Form.Item>

            <Form.Item
              label="Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: 'Enter address',
                },
              ]}
            >
              <Input placeholder="Token address" disabled />
            </Form.Item>

            <Form.Item
              label="Decimals"
              name="decimals"
              rules={[
                {
                  required: true,
                  message: 'Enter decimals',
                },
              ]}
            >
              <InputNumber placeholder="Token decimals" disabled />
            </Form.Item>

            <Form.Item
              label="Network"
              name="network"
              rules={[
                {
                  required: true,
                  message: 'Enter network',
                },
              ]}
            >
              <Input placeholder="Token network" disabled />
            </Form.Item>

            {/* <Form.Item
              label="Swap fee"
              name="fee_swap"
              rules={[
                {
                  required: true,
                  message: 'Enter swap fee',
                },
              ]}
            >
              <InputNumber placeholder="Swap fee" disabled />
            </Form.Item> */}

            <Form.Item
              label="Withdraw fee (TRX)"
              name="fee_withdraw"
              rules={[
                {
                  required: true,
                  message: 'Enter withdraw fee',
                },
              ]}
            >
              <InputNumber placeholder="Withdraw fee" disabled />
            </Form.Item>

            {/* <Form.Item
              label="Token fee"
              name="token_fee"
              rules={[
                {
                  required: true,
                  message: 'Enter token fee',
                },
              ]}
            >
              <Input placeholder="Token fee" disabled />
            </Form.Item> */}

            {/* <Form.Item
              label="Can swap"
              name="can_swap"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: 'Please select',
                },
              ]}
            >
              <Switch disabled/>
            </Form.Item> */}

            <Form.Item
              label="Price (USDT)"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Enter price',
                },
              ]}
            >
              <InputNumber placeholder="Price" disabled={modalData?.symbol !== 'NEWP' || !isEdit} />
            </Form.Item>

            {/* <Form.Item
              label="Can withdraw"
              name="can_withdraw"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: 'Please select',
                },
              ]}
            >
              <Switch disabled/>
            </Form.Item> */}
          </Form>

          <Flex flexDirection="column" style={{ gap: 10 }} width="100%">
            {modalData?.symbol === 'NEWP' && (
              <BaseButton onClick={handleSubmit} disabled={!modalData?.id} loading={loading}>
                {!isEdit ? 'Edit' : 'Confirm'}
              </BaseButton>
            )}

            <BaseButton onClick={handleClose} background="#ffecf2" textColor="#ff1964">
              Close
            </BaseButton>
          </Flex>
        </FormWrapper>
      </ModalBody>
    </ModalWrapper>
  )
}

export default ModalEditToken
