import BackButton from 'components/BaseButton/BackButton'
import React from 'react'
import styled from 'styled-components'
import { Tabs } from 'antd'
import AccountSwr from 'services/swr/account'
import TokenList from './TokenList'
import UserList from './UserList'

const Wrapper = styled.div`
  .ant-tabs-nav-list {
    width: 100%;

    .ant-tabs-tab {
      flex: 1;
      margin: 0;
      justify-content: center;

      > div {
        color: rgb(102, 102, 102);
      }

      &.ant-tabs-tab-active {
        div {
          color: rgb(254, 110, 162);
        }
      }
    }
  }

  .ant-tabs-ink-bar {
    background: rgb(254, 110, 162);
  }
`

const Admin = () => {
  const { data: userInfo } = AccountSwr.useGetMyProfile()
  if (!userInfo || userInfo?.username !== 'admin') return <></>

  return (
    <Wrapper>
      <BackButton title="Admin" />

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Token" key="1">
          <TokenList />
        </Tabs.TabPane>
        <Tabs.TabPane tab="User" key="2">
          <UserList />
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  )
}

export default Admin
