import React from 'react'
import { Image } from 'antd'
import BackButton from 'components/BaseButton/BackButton'
import { useHistory } from 'react-router-dom'
import AccountSwr from 'services/swr/account'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'

const Wrapper = styled.div`
  .wrap-item-token {
    min-height: 50vh;
    margin: 24px -20px;

    .item-token {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      background: rgba(255, 221, 230, 0.6);
      padding: 12px;
      margin-bottom: 1px;
      cursor: pointer;

      span {
        font-size: 13px;
        line-height: 1;
        color: #9397a4;
      }
    }

    .content {
      flex: 1;

	  > p {
		font-weight: 500;
	  }
    }

    .amount {
      font-size: 20px;
      font-weight: 700;

	  &.coming {
		font-size: 12px;
		font-weight: 400;
		opacity: 0.5;
	  }
    }
  }
`

const WithdrawPage = () => {
  const history = useHistory()
  const { data: balances } = AccountSwr.useGetBalance()

  return (
    <Wrapper>
      <BackButton title="Withdraw" />

      <div className="wrap-item-token">
        <div className="item-token" onClick={() => history.push('/withdraw/TRX')}>
          <Image src="/images/coins/TRX.png" preview={false} width="50px" />

          <div className="content">
            <p>TRX</p>
          </div>

          <div className="amount">{!balances ? '--' : addCommasToNumber(+balances?.TRX)}</div>
        </div>

        <div className="item-token" onClick={() => history.push('/withdraw/USDT')}>
          <Image src="/images/coins/tether_trc20.png" preview={false} width="50px" />

          <div className="content">
            <p>TRC20-USDT</p>
          </div>

          <div className="amount">{!balances ? '--' : addCommasToNumber(+balances?.USDT)}</div>
        </div>

        <div className="item-token" onClick={() => history.push('/withdraw/NEWP')}>
          <Image src="/images/tokens/NEWP-V1.png" preview={false} width="50px" />

          <div className="content">
            <p>NEWP</p>
          </div>

          <div className="amount">{!balances ? '--' : addCommasToNumber(+balances?.NEWP)}</div>
        </div>
      </div>
    </Wrapper>
  )
}

export default WithdrawPage
