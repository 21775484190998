import React from 'react'
import { Table, Tooltip } from 'antd'
import BaseButton from 'components/BaseButton'
import moment from 'moment-timezone'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'
import { formatUnits } from 'ethers/lib/utils'
import ModalWrapper from './ModalWrapper'

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  .ant-table-wrapper {
    * {
      font-size: 10px;

      @media screen and (min-width: 430px) {
        font-size: 12px;
      }
    }

    .ant-table-cell {
      padding: 12px 8px;
    }
  }
`

const columns: any = [
  {
    title: 'Amount',
    dataIndex: 'claimed_amount',
    key: 'claimed_amount',
    render: (amount, record) => {
      let decimal = 6
      if (record?.currency === "NEWP") decimal = 4

      return addCommasToNumber(+formatUnits(amount, decimal))
    },
  },
  {
    title: 'From date',
    dataIndex: 'interest_start',
    key: 'interest_start',
    render: (time) => (
      <p>
        <Tooltip placement="top" title={moment.utc(+time * 1000).format('YYYY-MM-DD HH:mm:ss')}>
          {moment.utc(+time * 1000).format('DD/MM/YYYY')}
        </Tooltip>
      </p>
    ),
  },
  {
    title: 'To date',
    dataIndex: 'interest_end',
    key: 'interest_end',
    render: (time) => (
      <p>
        <Tooltip placement="top" title={moment.utc(+time * 1000).format('YYYY-MM-DD HH:mm:ss')}>
          {moment.utc(+time * 1000).format('DD/MM/YYYY')}
        </Tooltip>
      </p>
    ),
  },
  {
    title: 'Claimed at',
    dataIndex: 'claimed_at',
    key: 'claimed_at',
    render: (time) => (
      <p>
        <Tooltip placement="top" title={moment.utc(+time * 1000).format('YYYY-MM-DD HH:mm:ss')}>
          {moment.utc(+time * 1000).format('DD/MM/YYYY')}
        </Tooltip>
      </p>
    ),
  },
]

const ModalPackageHistory = ({ visible, onCancel, modalData }) => {
  return (
    <ModalWrapper visible={visible} onCancel={onCancel} title="Claim History" width={500}>
      <ModalBody>
        <Table
          dataSource={modalData}
          columns={columns}
          scroll={{ x: 300, y: 300 }}
          loading={!modalData}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
          rowKey={(item) => item?.claimed_at}
        />

        <BaseButton background="#ffecf2" textColor="#ff1964" onClick={onCancel}>
          Close
        </BaseButton>
      </ModalBody>
    </ModalWrapper>
  )
}

export default ModalPackageHistory
