import React, { useState } from 'react'
import { Table, Input } from 'antd'
import AccountSwr from 'services/swr/account'
import styled from 'styled-components'

const { Search } = Input;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ant-input-search {
    border: 1px solid #fc709f !important;

    input {
      box-shadow: none;
    }
  }

  .ant-input-affix-wrapper {
    background: #fff0f5;
    border: none;
    box-shadow: none;
  }

  .ant-input-search-button {
    border-color: #d9d9d9 !important;
  }
`

const UserList = () => {
  const [params, setParams] = useState<{ page: number; pageSize?: number; search?: string }>({ page: 1 })
  const { data } = AccountSwr.useUserList(params)

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Ref code',
      dataIndex: 'ref_code',
      key: 'ref_code',
    },
    {
      title: 'Level',
      dataIndex: 'id',
      key: 'id',
      render: (value, item) => item?.user_account?.level || 0,
    },
  ]

  const onSearch: any = (value) => setParams({page: 1, search: value});

  return (
    <Wrapper>
      <Search placeholder="User search" onSearch={onSearch} allowClear/>

      <Table
        dataSource={data?.data}
        columns={columns}
        scroll={undefined}
        loading={!data}
        pagination={{
          hideOnSinglePage: data?.last_page === 1,
          showSizeChanger: false,
          total: data?.total,
          pageSize: data?.per_page,
          onChange: (page) => setParams((prev) => ({ ...prev, page })),
          current: data?.current_page,
        }}
        rowKey={(item) => item?.id}
      />
    </Wrapper>
  )
}

export default UserList
