const Routes = {
  home: '/',
  signIn: '/auth/sign-in',
  signUp: '/auth/sign-up',
  forgotPassword: '/auth/forgot-password',
  recharge: '/recharge',
  rechargePayment: '/recharge/payment',
  my: '/my',
  transactions: '/transactions',
  share: '/share',
  withdraw: '/withdraw',
  withdrawDetail: '/withdraw/:token',
  investment: '/investment',
  investmentDetail: '/investment/detail',
  referral: '/referral',
  admin: '/admin',
}

export default Routes