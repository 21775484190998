import fetchWrapper from 'helpers/fetch-wrapper'
import { authService } from 'services'
import useSWR, { SWRConfiguration } from 'swr'
import SWR_KEY from './constant'

const apiBaseUrl = `/transaction`

const useGetMyTransaction = (configs?: SWRConfiguration) => {
  const token = authService.getToken()
  const fetchData = async () => fetchWrapper.get(`${apiBaseUrl}/get-transaction-by-user`).then((res) => res?.data?.docs)

  return useSWR(token ? [SWR_KEY.GET_MY_TRANSACTION, token] : null, fetchData, {
    revalidateOnFocus: false,
	...configs
  })
}

const swap = async (body: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.post(`${apiBaseUrl}/swap`, body).then(async (data: any) => {
    if ((data?.status === 200 || data?.status === 201)) {
      if (cbs) cbs(data?.data)
      return data
    }
    if (cbe) cbe(data?.data)
    return data
  })
}

const TransactionSwr = {
	useGetMyTransaction,
  swap
} 

export default TransactionSwr
